export const packagesEN = {
    title: 'Applied Packages',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Module',
    column_generation_date: 'Generation date',
    column_issue_type: 'Issue type',
    column_resume: 'Resume',

    type_innovation: 'Innovation',
    type_maintenance: 'Maintenance',
    type_continuous_delivery: 'Continuous delivery',

    status_outdated_label: 'Outdated',
    status_uptodate_label: 'Up-to-date',

    details_source: 'Source',
    details_patch_date: 'Patch date',
    details_patch_time: 'Patch time',
    details_rpo_date: 'RPO date',
    details_rpo_time: 'RPO time',

    details_recommendation: 'Recommendation',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',
    filter_module: 'Module',
    filter_issue_type: 'Issue type',

    packages_texthelp:   `
                            <strong>Objective:</strong>
                            <p class="text-justify">Displays the list of packages applied in the environment indicated on the home page, as well as the details of each of these packages.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch: Internal package id;</p>
                            <p class="text-justify">-Ticket: Number of the first ticket that encountered the problem;</p>
                            <p class="text-justify">-Issue: Issue code that fixed the problem;</p>
                            <p class="text-justify">-Module: Module code that refers to the issue, which may be blank if not identified;</p>
                            <p class="text-justify">-Generation date: Package generation date;</p>
                            <p class="text-justify">-Issue type: Type of issue or package, which may be for Innovation, Maintenance or Continuous Delivery;</p>
                            <p class="text-justify">-Resume: Brief summary of TDN documentation;</p>
                            <br>
                            <strong>Search/Filter fields:</strong>
                            <p class="text-justify">By ticket, by issue or by module.</p>
                            <br>
                            <strong>More options:</strong>
                            <p class="text-justify">-Details: Displays the sources in the package.</p>
                            <p class="text-justify">-TDN documentation: Link to TDN documentation page.</p>
                        `,
}
