export const moduleES = {
    title: 'Paquete del módulo',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_gen_date: 'Fecha generación',
    column_p_date: 'Fecha de publicación',
    column_issue_type: 'Tipo del issue',
    column_resume: 'Resumen',
    column_count_applied: 'Aplicado',

    type_inovation: 'Innovación',
    type_maintenance: 'Mantenimiento',
    type_continuous_delivery: 'Expedición continúa',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    details_source: 'Fuente',
    details_patch_date: 'Fecha Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Fecha RPO',
    details_rpo_time: 'Hora RPO',

    tooltip_columns_count_applied: 'Los clientes han aplicado este paquete',

    combo_name_filter: 'Cambiar módulo',
    containter_text_model01: 'Aviso',
    containter_text_model02: 'En esta vista hay un filtro solo para paquetes de módulos: ',
    containter_text_model03: "Si es necesario ver otros módulos, cambie en 'Cambiar módulo' ",

    details_recommendation: 'Recomendación',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',

    module_texthelp:  `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Sugerir la lista de paquetes de módulos registrados de acuerdo con el análisis del ambiente indicado en la página de inicio y las fuentes que necesitan actualización.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id del paquete interno;</p>
                            <p class="text-justify">-Ticket: Número del primer ticket que encontró el problema;</p>
                            <p class="text-justify">-Issue: Código del issue que ha corregido el problema;</p>
                            <p class="text-justify">-Modulo: Código del módulo referente al issue, puede ser blanco si no está identificado;</p>
                            <p class="text-justify">-Fecha generación: Fecha en que se generó el paquete;</p>
                            <p class="text-justify">-Tipo de Issue: En esta página siempre el Mantenimiento;</p>
                            <p class="text-justify">-Resumen: Breve resumen de la documentación TDN;</p>
                            <br>
                            <strong>Campos de Búsqueda/Filtro:</strong>
                            <p class="text-justify">Por Ticket y Por Issue.</p>
                            <br>
                            <strong>Mas opciones:</strong>
                            <p class="text-justify">-Detalles: Muestra las fuentes contenidas en el paquete.</p>
                            <p class="text-justify">-Documentación TDN: Acceso a la página TDN con la documentación.</p>
                            <p class="text-justify">-Descargar: Redirige al enlace a la página de descarga.</p>
                        `,
}
