import { Injectable } from "@angular/core";
import { PoI18nService } from "@po-ui/ng-components";

@Injectable({
  providedIn: "root",
})
export class LiteralService {
  public literals = {};
  public accumulated = {};
  public application = {};
  public bin_recommended = {};
  public contact = {};
  public dictionary = {};
  public home = {};
  public module = {};
  public packages = {};
  public rpo_list = {};
  public source = {};
  public procemp = {};
  public empproc = {};
  public subscription = {};
  public modal = {};

  private brwLanguage: string = 'pt';

  constructor(private poI18nService: PoI18nService) {
    const aLanguages = ['pt', 'en', 'es'];

    if (aLanguages.indexOf(navigator.language.substring(0, 2)) < 0) {
      this.brwLanguage = 'pt'; // default será portugês 'pt'
    } else {
      this.brwLanguage = navigator.language.substring(0, 2); // obtém o idioma do browser, pois é válido (inglês, espanhol ou português)
    }

    this.poI18nService
      .getLiterals({ language: this.brwLanguage })
      .subscribe((response) => (this.literals = response));

    this.poI18nService
      .getLiterals({ context: "accumulated", language: this.brwLanguage })
      .subscribe((response) => (this.accumulated = response));

    this.poI18nService
      .getLiterals({ context: "application", language: this.brwLanguage })
      .subscribe((response) => (this.application = response));

    this.poI18nService
      .getLiterals({ context: "bin_recommended", language: this.brwLanguage })
      .subscribe((response) => (this.bin_recommended = response));

    this.poI18nService
      .getLiterals({ context: "contact", language: this.brwLanguage })
      .subscribe((response) => (this.contact = response));

    this.poI18nService
      .getLiterals({ context: "dictionary", language: this.brwLanguage })
      .subscribe((response) => (this.dictionary = response));

    this.poI18nService
      .getLiterals({ context: "home", language: this.brwLanguage })
      .subscribe((response) => (this.home = response));

    this.poI18nService
      .getLiterals({ context: "module", language: this.brwLanguage })
      .subscribe((response) => (this.module = response));

    this.poI18nService
      .getLiterals({ context: "packages", language: this.brwLanguage })
      .subscribe((response) => (this.packages = response));

    this.poI18nService
      .getLiterals({ context: "rpo_list", language: this.brwLanguage })
      .subscribe((response) => (this.rpo_list = response));

    this.poI18nService
      .getLiterals({ context: "sources", language: this.brwLanguage })
      .subscribe((response) => (this.source = response));

    this.poI18nService
      .getLiterals({ context: "procemp", language: this.brwLanguage })
      .subscribe((response) => (this.procemp = response));

    this.poI18nService
      .getLiterals({ context: "empproc", language: this.brwLanguage })
      .subscribe((response) => (this.empproc = response));

    this.poI18nService
      .getLiterals({ context: "subscription", language: this.brwLanguage })
      .subscribe((response) => (this.subscription = response));

    this.poI18nService
      .getLiterals({ context: "modal", language: this.brwLanguage })
      .subscribe((response) => (this.modal = response));

  }
}
