export const applicationEN = {
    title: 'Update Center - Protheus',
    title_slide: 'Notifications',
    title_slide_config: 'Notification Management',
    my_environment: 'My environment',
    environment_label: 'Environment',
    sub_item_packages: 'Applied packages',
    sub_item_packages_1: 'Packages Vs Source',
    sub_item_packages_2: 'Source Vs Package',
    sub_item_dictionary: 'Dictionary',
    sub_item_procedures: 'Procedures',
    sub_item_procedures_1: 'Company x Process',
    sub_item_procedures_2: 'Process x Company',
    available_updates: 'Available updates',
    available_updates_label: 'Updates',
    sub_item_accumulated_packages: 'Accumulated packages',
    sub_item_module_packages: 'Module packages',
    sub_item_source_list: 'Source list',
    sub_item_lib_and_bin: 'Library & Binaries',
    suggestions: 'Suggestions',
    suggestions_label: 'Suggestions',
    notification_config_title: "Configurations",
    notifications_label: 'Notifications',
    notifications_settings: 'Manage subscriptions',
    notifications_columns_title: ['Date', 'Title', 'Resume'],
    msg_session_expired: 'Session expired! Please, open the page again.',
    msg_session_unauthorized: 'Unauthorized session! Please log in again.',
    msg_request_incomplete: 'The request could not be completed.',
    subscriptions: 'Subscriptions',
    subscriptions_label: 'Subscription to Notifications',

    //-- literais usadas no componente "dynamic-search-page"
    dsp_filter_title: 'Advanced search',
    dsp_group_title: 'Applied filters',
    dsp_cancel_btn: 'Cancel',
    dsp_confirm_btn: 'Apply',
    dsp_search_placeholder: 'search here',
    dsp_details: 'Details',
    dsp_tdn_documentation: 'TDN Documentation',
    dsp_tdn_documentation_details: 'TDN Doc.',
    dsp_no_package_documentation: 'The selected package has no documentation available',
    dsp_hlp_how_to_use: 'How to use?',
    dsp_hlp_resume: 'Resume',
    dsp_hlp_search: 'Search',

}
