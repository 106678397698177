export const bin_recommendedES = {
    title: 'Lib y Binarios',

    column_bin_type: 'Tipo de Binarios',
    column_platform: 'Plataforma',
    column_bin_version: 'Versión en el ambiente',
    column_approved_version: 'Versión Aprobado',
    column_build_version:'Build en el ambiente',
    column_approved_build:'Build Aprobado',
    column_published_date: 'Fecha de publicación',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    details_bin_type: 'Tipo de Binarios',
    details_version: 'Versión',
    details_platform: 'Plataforma',
    details_resume: 'Resumen',

    filter_bin_type: 'Tipo de Binarios',
    filter_platform: 'Plataforma',

    bin_recommended_texthelp:   `
                                    <strong>Objetivo:</strong>
                                    <p class="text-justify">Muestra la lista de libs y binarios evaluados, comparando la versión del ambiente indicada en la página de inicio con la última disponible.</p>
                                    <br>
                                    <strong>Campos:</strong>
                                    <p class="text-justify">-Status: Indica si actualizado o no;</p>
                                    <p class="text-justify">-Tipo de Binarios: Lib, Appserver y DbAccess;</p>
                                    <p class="text-justify">-Plataforma: Indica la plataforma del binario, Windows o Linux, 32 o 64 Bits;</p>
                                    <p class="text-justify">-Versión: Versión en el ambiente evaluado;</p>
                                    <p class="text-justify">-Build: Build en el ambiente evaluado;</p>
                                    <p class="text-justify">-Versión Aprobado: Última versión disponible para descarga;</p>
                                    <p class="text-justify">-Build Aprobado: Última Build disponible para descarga;</p>
                                    <br>
                                    <strong>Campos de Búsqueda/Filtro:</strong>
                                    <p class="text-justify">Por Tipo de Binario y Por Plataforma.</p>
                                    <br>
                                    <strong>Mas opciones:</strong>
                                    <p class="text-justify">-Detalles: Muestra la lista de versiones entre la versión del ambiente y la versión de Totvs, así como cada documentación específica.</p>
                                    <p class="text-justify">-Descargar: Redirige al enlace a la página de descarga.</p>
                                `,

  title_artefacts_additional: 'Artefactos adicionales',
  obs_artects_additional: 'No es posible comparar el estado de aprobación de la lista de artefactos anterior.',
}
