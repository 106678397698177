export const empprocES = {
    title: 'Empresa x Proceso',

    //Coluna da tabela principal (tela inicial do component)
    column_company: 'Codigo de compañia',
    column_total_outdated: 'Tot. desactualizado',
    column_total_notinstalled: 'Tot. no instalado',
    column_total_updated: 'Tot. actualizado',

    detail_spsprocdure: 'Id. Sps',
    details_spstatus: 'Estado del processo',
    details_sps_clientspsid: 'Id. Client',
    details_sps_clientdata: 'Fecha y hora Client',
    details_sps_totvsspsid: 'Id. Totvs',
    details_sps_totvsdata: 'Fecha y hora Totvs',
    details_company: 'Compañia',
    details_process: 'Código de proceso',
    details_idsps: 'Id. Sps',
    details_datahora: 'Fecha y hora',
    details_sps_clienthour: 'Tiempo del cliente',
    details_sps_totvshour: 'Tiempo de Servidor',
    details_histdata: 'Fecha del historial',
    details_histhour: 'Tiempo del historial',

    details_label_updated: 'Actualizado',
    details_label_outdated: 'Desactualizado',
    details_label_not_installed: 'No instalado',

    details_hist_action: 'Historial de existencias',
    details_label_install: 'Instalado',
    details_label_remove: 'Eliminado',

    dsp_row_historical: 'Histórico',
    dsp_details: 'Detalles',

    empproc_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Muestre el listado de empresas por procedimientos almacenados, detallando cada proceso y su estado actual en su entorno.</p>
                                <br>
                                <strong>Los campos:</strong>
                                <p class="text-justify">-Compañía: Código de la compañía Protheus </p>
                                <strong>Totalizadores:</strong>
                                <p class="text-justify">-No instalado: Suma de cada procedimiento no instalado en su grupo de empresas por proceso. </p>
                                <p class="text-justify">-Desactualizado: suma de cada procedimiento desactualizado en su grupo de empresas por proceso. </p>
                                <p class="text-justify">-Actualizado: Suma de cada trámite actualizado en su grupo de empresas por proceso. </p>
                                <br>
                                <strong>Campos de búsqueda / filtro:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>Mas opciones:</strong>
                                <p class="text-justify">-Id. Sps: Código de procedimiento almacenado. </p>
                                <p class="text-justify">-Id de cliente: identificador de versiones del procedimiento almacenado. </p>
                                <p class="text-justify">-Fecha Cliente: fecha de la última actualización del procedimiento almacenado en el cliente. </p>
                                <p class="text-justify">-Totvs Id: identificador de versiones del procedimiento almacenado en la central. </p>
                                <p class="text-justify">-Fecha Totvs: fecha de la última actualización del procedimiento almacenado en la central. </p>
                                <p class="text-justify">-Estado del procedimiento: </p>
                                <p class="text-justify">-Actualizado: el identificador de procedimiento almacenado es idéntico al proporcionado por la central.  </p>
                                <p class="text-justify">-Desactualizado: el identificador de procedimiento almacenado es inferior al identificador proporcionado por la central.  </p>
                                <p class="text-justify">-No instalado: el ID instalado en el entorno del cliente no está identificado.  </p>
                                <br>
                                <strong>Histórico:</strong>
                                <p class="text-justify">-Compañía: Código de la compañía. </p>
                                <p class="text-justify">-Id. Sps: identificador de versiones del procedimiento almacenado. </p>
                                <p class="text-justify">-Código de proceso: código secuencial del procedimiento almacenado. </p>
                                <p class="text-justify">-Fecha y hora: fecha y hora de la acción.  </p>
                                <p class="text-justify">-Estado del procedimiento: </p>
                                <p class="text-justify">-Eliminado: se eliminó el procedimiento almacenado de la empresa.  </p>
                                <p class="text-justify">-Instalado: Instale el procedimiento almacenado .  </p>
                            `,
}
