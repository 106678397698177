export const homePT = {
    title: 'Clientes',
    appserver_group: 'APPSERVER',
    library_group: 'LIB',
    dbaccess_group: 'DBACCESS',
    version_label: 'Versão',
    status_ok: 'Atualizado',
    customer_group: 'Dados do Cliente',
    customer_id_label: 'ID',
    customer_name_label: 'Nome',
	environment_group: 'Ambiente',
	environment_name_label: 'Nome',
	environment_server_label: 'Servidor',
	environment_port_label: 'Porta',
	environment_version_label: 'Versão',
	environment_dbname_label: 'Nome DB',
    environment_dbmstype_label: 'Tipo DB',
    environment_rposubmark_label:'RPO Sub-Marca',
    environment_dicsubmark_label:'Dicionário Sub-Marca',
    environment_dicindb_label:'Dicionário no DB',
    environment_dicindb_yes:'Sim',
    environment_dicindb_no:'Não',
    environment_apiversion_label: 'Versão Central',
    environment_installtype_label: 'Tipo de Instalação',
    update_group: 'Ultima atualização',
    update_date: 'Data',
    update_hour: 'Hora',
}
