export const empprocEN = {
    title: 'Company x Stored Procedure',

    //Coluna da tabela principal (tela inicial do component)
    column_company: 'Company',
    column_total_outdated: 'Total Outdated',
    column_total_notinstalled: 'Total not Installed',
    column_total_updated: 'Total updated',

    detail_spsprocdure: 'Id. Sps',
    details_spstatus: 'Procedure Status',
    details_sps_clientspsid: 'Client ID',
    details_sps_clientdata: 'Date client',
    details_sps_totvsspsid: 'Totvs ID',
    details_sps_totvsdata: 'Date totvs',
    details_company: 'Company',
    details_process: 'Cod. of the Process',
    details_idsps: 'Id. Sps',
    details_datahora: 'Date time',
    details_sps_clienthour: 'Time Client',
    details_sps_totvshour: 'Time Totvs',
    details_histdata: 'Date History',
    details_histhour: 'Time History',

    details_label_updated: 'Updated',
    details_label_outdated: 'Outdated',
    details_label_not_installed: 'Not Installed',

    details_hist_action: 'Stock history',
    details_label_install: 'Installed',
    details_label_remove: 'Removed',

    dsp_row_historical: 'History',
    dsp_details: 'Details',

    empproc_texthelp:    `
                                <strong>Objective:</strong>
                                <p class="text-justify">Show the list of companies by stored procedures, detailing each process and its current status in your environment.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Company: Protheus Company Code </p>
                                <strong>Totalizers:</strong>
                                <p class="text-justify">-Not installed: Sum of each procedure not installed in your group of companies by process. </p>
                                <p class="text-justify">-Outdated: Sum of each outdated procedure in your group of companies per process. </p>
                                <p class="text-justify">-Updated: Sum of each procedure updated in your group of companies by process. </p>
                                <br>
                                <strong>Search/Filter Fields:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>More options:</strong>
                                <p class="text-justify">-Id. Sps: Stored Procedure Code. </p>
                                <p class="text-justify">-Client Id: Stored Procedure versioning identifier. </p>
                                <p class="text-justify">-Date Client: Date of the last Stored Procedure update on the Client. </p>
                                <p class="text-justify">-Totvs Id: Stored Procedure versioning identifier in the central. </p>
                                <p class="text-justify">-Date Totvs: Date of the last Stored Procedure update in the central. </p>
                                <p class="text-justify">-Procedure Status: </p>
                                <p class="text-justify">-Updated: Stored Procedure identifier is identical to the one provided by the central. </p>
                                <p class="text-justify">-Outdated: Stored Procedure identifier is inferior to the identifier provided by the central. </p>
                                <p class="text-justify">-Not Installed: The Id installed in the client environment is not identified. </p>
                                <br>
                                <strong>Historical:</strong>
                                <p class="text-justify">-Company: Company code. </p>
                                <p class="text-justify">-Id. Sps: Stored Procedure versioning identifier. </p>
                                <p class="text-justify">-Process Code: Stored Procedure sequential code. </p>
                                <p class="text-justify">-Date and Time: Date and time of the action. </p>
                                <p class="text-justify">-Procedure Status: </p>
                                <p class="text-justify">-Removed: Removed the company's Stored Procedure. </p>
                                <p class="text-justify">-Installed: Install the Stored Procedure. </p>
                            `,
}
