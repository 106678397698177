export const packagesES = {
    title: 'Paquetes aplicados',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_gen_date: 'Fecha generación',
    column_issue_type: 'Tipo del issue',
    column_resume: 'Resumen',

    type_innovation: 'Innovación',
    type_maintenance: 'Mantenimiento',
    type_continuous_delivery: 'Expedición continúa',

    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    details_source: 'Fuente',
    details_patch_date: 'Fecha Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Fecha RPO',
    details_rpo_time: 'Hora RPO',

    details_recommendation: 'Recomendación',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',
    filter_module: 'Module',
    filter_issue_type: 'Issue type',

    packages_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Muestra la lista de paquetes aplicados en el ambiente indicado en la página de inicio, así como los detalles de cada uno de estos paquetes.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id del paquete interno;</p>
                            <p class="text-justify">-Ticket: Número del primer ticket que encontró el problema;</p>
                            <p class="text-justify">-Issue: Código del issue que ha corregido el problema;</p>
                            <p class="text-justify">-Modulo: Código del módulo referente al issue, puede ser blanco si no está identificado;</p>
                            <p class="text-justify">-Fecha generación: Fecha en que se generó el paquete;</p>
                            <p class="text-justify">-Tipo del Issue: Tipo del issue o paquete, que puede ser de mantenimiento, innovación o expedición continúa;</p>
                            <p class="text-justify">-Resumen: Breve resumen de la documentación TDN;</p>
                            <br>
                            <strong>Campos de Búsqueda/Filtro:</strong>
                            <p class="text-justify">Por ticket, por Issue o por Modulo.</p>
                            <br>
                            <strong>Mas opciones:</strong>
                            <p class="text-justify">-Detalles: Muestra las fuentes contenidas en el paquete.</p>
                            <p class="text-justify">-Documentación TDN: Acceso a la página TDN con la documentación.</p>
                        `,

}
