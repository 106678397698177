import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PoTableColumn } from '@po-ui/ng-components';

import { environment } from 'src/environments/environment';
import { LiteralService } from '../i18n/literal.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    private readonly URL_API = environment.url_api;

    protected endpoint: string;
    protected endpointdetail: string;
    protected endpointhistorical: string;
    protected endpointToPost: string;
    protected literalContext: string;
    protected linkToGif: string;
    protected textHelp:string;
    protected columns: Array<PoTableColumn>;
    protected columnsExtras: Array<PoTableColumn>;
    protected columnsDetails: Array<PoTableColumn>;
    protected columnsDetailsHistorical: Array<PoTableColumn>;
    protected columnsFilters: Array<any>;
    protected filterDetail: boolean;
    protected combo_name_filter: string;
    protected containter_text_model01: string;
    protected containter_text_model02: string;
    protected containter_text_model03: string;

    public literals: object;

    constructor(private http: HttpClient, literalService: LiteralService) {
        this.literals = literalService.literals;
    }

    get(queryParams = {}): Observable<any> {
        const params = new HttpParams({
            fromObject: {...queryParams, fields: this.getColumns().map(f => f.property).join(','), lang: navigator.language }
          });
        return this.http.get(`${this.URL_API}${this.endpoint}`, { params },);
    }

    getDetail(queryParams = {}): Observable<any> {
        const params = new HttpParams({
            fromObject: {...queryParams, fields: this.getColumnsDetails().map(f => f.property).join(','), lang: navigator.language}
          });
        return this.http.get(`${this.URL_API}${this.endpointdetail}`, { params },);
    }

    getHistorical(queryParams = {}): Observable<any> {
        const params = new HttpParams({
            fromObject: {...queryParams, fields: this.getColumnsDetailsHistorical().map(f => f.property).join(','), lang: navigator.language}
          });
        return this.http.get(`${this.URL_API}${this.endpointhistorical}`, { params },);
    }

    getSimple(queryParams = {}): Observable<any> {
      const params = new HttpParams({
        fromObject: { ...queryParams, lang: navigator.language }
      });
      return this.http.get(`${this.URL_API}${this.endpointdetail}`, { params },);
    }

    // Método POST
    post(data: any): Observable<any> {
      const dataWithLang = { ...data, lang: navigator.language };
      return this.http.post<any>(`${this.URL_API}${this.endpointToPost}`, dataWithLang);
    }

    // Método PUT
    put(data: any): Observable<any> {
      return this.http.put<any>(`${this.URL_API}${this.endpoint}`, data);
    }

    getColumns(): Array<PoTableColumn> {
        return this.columns;
    }

    getColumnsDetails(): Array<PoTableColumn> {
        return this.columnsDetails;
    }

    getColumnsDetailsHistorical(): Array<PoTableColumn> {
        return this.columnsDetailsHistorical;
    }

    getColumnsFilters(): Array<PoTableColumn> {
        return this.columnsFilters;
    }

    getColumnsExtras(): Array<PoTableColumn> {
      return this.columnsExtras;
  }

}
