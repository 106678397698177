import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HttpService } from "./services/http.service";

import { PoI18nConfig, PoI18nModule } from "@po-ui/ng-components";
import { LiteralService } from "./i18n/literal.service";

import { accumulatedPT } from "./i18n/accumulated-pt";
import { accumulatedEN } from "./i18n/accumulated-en";
import { accumulatedES } from "./i18n/accumulated-es";

import { applicationPT } from "./i18n/application-pt";
import { applicationEN } from "./i18n/application-en";
import { applicationES } from "./i18n/application-es";

import { bin_recommendedPT } from "./i18n/bin_recommended-pt";
import { bin_recommendedEN } from "./i18n/bin_recommended-en";
import { bin_recommendedES } from "./i18n/bin_recommended-es";

import { contactPT } from "./i18n/contact-pt";
import { contactEN } from "./i18n/contact-en";
import { contactES } from "./i18n/contact-es";

import { dictionaryPT } from "./i18n/dictionary-pt";
import { dictionaryEN } from "./i18n/dictionary-en";
import { dictionaryES } from "./i18n/dictionary-es";

import { homePT } from "./i18n/home-pt";
import { homeEN } from "./i18n/home-en";
import { homeES } from "./i18n/home-es";

import { modulePT } from "./i18n/module-pt";
import { moduleEN } from "./i18n/module-en";
import { moduleES } from "./i18n/module-es";

import { packagesPT } from "./i18n/packages-pt";
import { packagesEN } from "./i18n/packages-en";
import { packagesES } from "./i18n/packages-es";

import { rpo_listPT } from "./i18n/rpo_list-pt";
import { rpo_listEN } from "./i18n/rpo_list-en";
import { rpo_listES } from "./i18n/rpo_list-es";

import { sourcesPT } from "./i18n/sources-pt";
import { sourcesEN } from "./i18n/sources-en";
import { sourcesES } from "./i18n/sources-es";

import { procempES } from "./i18n/procemp-es";
import { procempEN } from "./i18n/procemp-en";
import { procempPT } from "./i18n/procemp-pt";

import { empprocPT } from "./i18n/empproc-pt";
import { empprocEN } from "./i18n/empproc-en";
import { empprocES } from "./i18n/empproc-es";

import { subscriptionPT } from "./i18n/subscription-pt";
import { subscriptionEN } from "./i18n/subscription-en";
import { subscriptionES } from "./i18n/subscription-es";

import {enableProdMode} from '@angular/core';
import { modalPT } from "./i18n/modal-pt";
import { modalEN } from "./i18n/modal-en";
import { modalES } from "./i18n/modal-es";

enableProdMode();

const i18nConfig: PoI18nConfig = {
  default: {
    //language: "pt",
    context: "application",
    cache: false,
  },
  contexts: {
    accumulated: {
      "pt": accumulatedPT,
      "en": accumulatedEN,
      "es": accumulatedES
    },
    application: {
      "pt": applicationPT,
      "en": applicationEN,
      "es": applicationES
    },
    bin_recommended: {
      "pt": bin_recommendedPT,
      "en": bin_recommendedEN,
      "es": bin_recommendedES
    },
    contact: {
      "pt": contactPT,
      "en": contactEN,
      "es": contactES
    },
    dictionary: {
      "pt": dictionaryPT,
      "en": dictionaryEN,
      "es": dictionaryES
    },
    home: {
      "pt": homePT,
      "en": homeEN,
      "es": homeES
    },
    module: {
      "pt": modulePT,
      "en": moduleEN,
      "es": moduleES
    },
    packages: {
      "pt": packagesPT,
      "en": packagesEN,
      "es": packagesES
    },
    rpo_list: {
      "pt": rpo_listPT,
      "en": rpo_listEN,
      "es": rpo_listES
    },
    sources: {
      "pt": sourcesPT,
      "en": sourcesEN,
      "es": sourcesES
    },
    procemp: {
      "pt": procempPT,
      "en": procempEN,
      "es": procempES
    },
    empproc: {
      "pt": empprocPT,
      "en": empprocEN,
      "es": empprocES
    },
    subscription: {
      "pt": subscriptionPT,
      "en": subscriptionEN,
      "es": subscriptionES
    },
    modal: {
      "pt": modalPT,
      "en": modalEN,
      "es": modalES
    },
  },
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PoI18nModule.config(i18nConfig)
  ],
  providers: [
    HttpService,
    LiteralService
  ],
})
export class CoreModule { }
