export const moduleEN = {
    title: 'Module packages',

    column_id: 'Patch id',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Module',
    column_gen_date: 'Generation date',
    column_p_date: 'Publication date',
    column_issue_type: 'Issue type',
    column_resume: 'Resume',
    column_count_applied: 'Applied',

    type_inovation: 'Innovation',
    type_maintenance: 'Maintenance',
    type_continuous_delivery: 'Continuous delivery',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Outdated',
    status_uptodate_label: 'Up-to-date',

    details_source: 'Source',
    details_patch_date: 'Patch date',
    details_patch_time: 'Patch time',
    details_rpo_date: 'RPO date',
    details_rpo_time: 'RPO time',

    tooltip_columns_count_applied: 'Customers have applied this package',

    combo_name_filter: 'Change module',
    containter_text_model01: 'Caution',
    containter_text_model02: 'This view there is filter only for module packages: ',
    containter_text_model03: "If there is a need to view other modules, change in 'Change module' ",

    details_recommendation: 'Recommendation',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',

    module_texthelp:  `
                            <strong>Objective:</strong>
                            <p class="text-justify">Suggest the list of logged module packages that have sources to update according to the analysis of the environment indicated on the Home page.</p>
                            <br>
                            <strong>Fields:</strong>
                            <p class="text-justify">-Patch id: Internal package id;</p>
                            <p class="text-justify">-Ticket: Number of the first ticket that encountered the problem;</p>
                            <p class="text-justify">-Issue: Issue code that fixed the problem;</p>
                            <p class="text-justify">-Modulr: Module code that refers to the issue;</p>
                            <p class="text-justify">-Generation date: Package generation date;</p>
                            <p class="text-justify">-Issue type: On this page, always refers to 'Maintenance';</p>
                            <p class="text-justify">-Resume: Brief summary of TDN documentation;</p>
                            <br>
                            <strong>Search/Filter fields:</strong>
                            <p class="text-justify">By ticket or by issue.</p>
                            <br>
                            <strong>More options:</strong>
                            <p class="text-justify">-Details: Displays the sources in the package.</p>
                            <p class="text-justify">-TDN documentation: Link to TDN documentation page..</p>
                            <p class="text-justify">-Download: Link to the download page.</p>
                        `,
}
