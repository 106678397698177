export const rpo_listPT = {
    title: 'Lista de Fontes',

    status_outdated_value: 'RPO Desatualizado',
    status_uptodate_value: 'RPO Atualizado',
    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    column_source: 'Fonte',
    column_patch_date: 'Data Patch',
    column_patch_time: 'Hora Patch',
    column_rpo_date: 'Data RPO',
    column_rpo_time: 'Hora RPO',

    details_issue: 'Issue',
    details_recommendation: 'Recomendação',

    filter_source: 'Fonte',

    rpo_list_texthelp:  `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Exibir a lista de todos os fontes do RPO exceto lib e comparar a data do fonte no RPO com a última versão disponível na Totvs.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Fonte: Nome do Fonte;</p>
                            <p class="text-justify">-Data Patch: Data da última versão disponível;</p>
                            <p class="text-justify">-Hora Patch: Hora da última versão disponível;</p>
                            <p class="text-justify">-Data RPO: Data do fonte no RPO;</p>
                            <p class="text-justify">-Hora RPO: Hora do fonte no RPO;</p>
                            <p class="text-justify">-Status: Avalia se está atualizado ou não, respeitando possíveis diferenças de compilação. Obs: Fontes com 1 hora exata de diferença serão considerados iguais;</p>
                            <br>
                            <strong>Campos para Busca/Filtro:</strong>
                            <p class="text-justify">Por fonte.</p>
                            <br>
                            <strong>Mais opções:</strong>
                            <p class="text-justify">-Detalhes: Exibe os pacotes que contém o fonte e possibilita baixá-los. O pacote de issue com o fonte mais atual e as expedições contínuas que possuírem o fonte mais atualizado que no RPO serão relacionados.</p>
                        `,

}
