export const subscriptionES = {
  title: 'Gestión de Notificaciones',
  labelCodT: 'Código T.',
  labelTotvsId: 'TotvsId',
  labelVersion: 'Versión',
  labelEnvironment: 'Entorno',
  labelServer: 'Servidor',
  labelPort: 'Puerto',
  labelButtonClose: 'Cerrar',
  labelButtonSave: 'Guardar',

  description_subscriber: 'Elija las categorías para recibir notificaciones:',
  description_all: 'Todos',

  post_success: 'Inscripción realizada exitosamente.'
}