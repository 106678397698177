export const homeEN = {
    title: 'Customer',
    appserver_group: 'APPSERVER',
    library_group: 'LIB',
    dbaccess_group: 'DBACCESS',
    version_label: 'Version',
    status_ok: 'Up-to-date',
    customer_group: 'Customer info',
    customer_id_label: 'ID',
    customer_name_label: 'Name',
	environment_group: 'Environment',
	environment_name_label: 'Name',
	environment_server_label: 'Server',
	environment_port_label: 'Port',
	environment_version_label: 'Version',
	environment_dbname_label: 'DB Name',
    environment_dbmstype_label: 'DB Type',
    environment_rposubmark_label:'RPO Sub-Mark',
    environment_dicsubmark_label:'Dictionary Sub-Mark',
    environment_dicindb_label:'Dictionary in DB',
    environment_dicindb_yes:'Yes',
    environment_dicindb_no:'No',
    environment_apiversion_label: 'Center Version',
    environment_installtype_label: 'Installation Type',
    update_group: 'Last update',
    update_date: 'Date',
    update_hour: 'Hour',
}
