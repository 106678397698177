export const rpo_listES = {
    title: 'Lista del Fuentes',

    status_outdated_value: 'RPO Desatualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_uptodate_value: 'RPO Atualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    column_source: 'Fuente',
    column_patch_date: 'Fecha Patch',
    column_patch_time: 'Hora Patch',
    column_rpo_date: 'Fecha RPO',
    column_rpo_time: 'Hora RPO',

    details_issue: 'Issue',
    details_recommendation: 'Recomendación',

    filter_source: 'Fuente',

    rpo_list_texthelp:  `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Muestre la lista de todos los fuentes del RPO excepto lib y compare la fecha de la fuente en el RPO con la última versión disponible de Totvs.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Fuente: Nombre del Fuente;</p>
                            <p class="text-justify">-Fecha Patch: Fecha de la última versión disponible;</p>
                            <p class="text-justify">-Hora Patch: Hora de la última versión disponible;</p>
                            <p class="text-justify">-Fecha RPO: Fecha del fuente en el RPO;</p>
                            <p class="text-justify">-Hora RPO: Hora del fuente en el RPO;</p>
                            <p class="text-justify">-Status: Evalúa si está actualizado o no, respetando las posibles diferencias de la compilación. Nota: Las fuentes con una diferencia exacta de 1 hora se considerarán iguales;</p>
                            <br>
                            <strong>Campos de Búsqueda/Filtro:</strong>
                            <p class="text-justify">Por fuente.</p>
                            <br>
                            <strong>Mas opciones:</strong>
                            <p class="text-justify">-Detalles: muestra los paquetes que contienen el fuente y permite descargarlos. Se listará el paquete de emisión con el fuente más actual y las Expediciónes continúas que tienen el fuente más actualizado que en el RPO.</p>
                        `,
}
