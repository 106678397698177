export const contactEN = {
    title: "Suggestions",
    contact_btn_send: 'Send',
    contact_name: 'Name',
    contact_phone: 'Phone',
    contact_mail: 'E-mail',
    contact_comments: 'Comment',
    contact_success_msg: 'Suggestion sent successfully!',
    contact_btn_term_accept: 'I accept',
    contact_btn_term_not_accept: 'I do not accept',
    contact_title_term: 'Acceptance term',
    contact_subtitle_term: 'Update Center - Protheus',
    contact_text_term: `
                        <p>
                            We keep the name, phone and email data just for analysis of the suggestion and eventual future assistance.
                            By clicking <strong>"I accept"</strong>, you consent to the use of this data.
                        </p>
                        `,

}
