// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var currentUrl = window.location.href;
if (/^.*localhost.*/.test(currentUrl)) {
  var API_BASE_URL = 'http://localhost:81/patchweb/front/'
} else if (/^.*tph.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph.engpro.totvs.com.br/front/'
} else if (/^.*tph-homolog.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph-homolog.engpro.totvs.com.br/front/'
} else if (/^.*tph.pre.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph.pre.engpro.totvs.com.br/front/'
}else{
  var API_BASE_URL = 'https://api.tph.engpro.totvs.com.br/front/'
}

export const environment = {
  production: true,
  url_api: API_BASE_URL,
  };

