export const dictionaryPT = {
    title: 'Dicionário',
    customer: 'Cliente',
    column_id: 'Id Execução',
    column_group: "Grupo",
    column_exec_date: 'Data execução',
    column_exec_time: 'Hora execução',
    column_process_id: 'Id processamento',
    column_project_id: 'Id do projeto',
    column_package_id: 'Id do pacote',
    column_package_details: 'Detalhamento pacote',

    details_type: 'Tipo',
    details_description: 'Descrição',

    filter_project_id: 'Id do projeto',
    filter_package_id: 'Id do pacote',
    filter_exec_date: 'Data Exec. De',

    dictionary_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Mostrar a lista das execuções de upddistr, detalhando o pacote que foi aplicado e não as alterações efetuadas na base de dados.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Id Execução: ID de execução sequencial;</p>
                                <p class="text-justify">-Grupo: Grupo de empresa que executou o upddistr;</p>
                                <p class="text-justify">-Data Execução: Data que foi executado o upddistr;</p>
                                <p class="text-justify">-Hora Execução: Hora que foi executado o upddistr;</p>
                                <p class="text-justify">-Id Processamento: Id de processamento que vincula os logs de alteração de base;</p>
                                <p class="text-justify">-Id do Projeto: Id interno do projeto que o pacote se refere;</p>
                                <p class="text-justify">-Id do Pacote: Id interno do pacote ;</p>
                                <p class="text-justify">-Detalhe pacote: Descritivo detalhando o pacote;</p>
                                <br>
                                <strong>Campos para Busca/Filtro:</strong>
                                <p class="text-justify">Por Id de Pacote, Por Id de Projeto e Data de execução.</p>
                                <br>
                                <strong>Mais opções:</strong>
                                <p class="text-justify">-Detalhes: Detalhes adicionais do pacote.</p>
                            `,
}
