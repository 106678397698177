export const accumulatedEN = {
    title: 'Accumulated packages',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Module',
    column_generation_date: 'Generation date',
    column_public_date: 'Publication date',
    column_issue_type: 'Issue type',
    column_resume: 'Resume',
    column_count_applied: 'Applied',

    type_innovation: 'Innovation',
    type_maintenance: 'Maintenance',
    type_continuous_delivery: 'Continuous delivery',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Outdated',
    status_uptodate_label: 'Up-to-date',

    details_source: 'Source',
    details_patch_date: 'Patch date',
    details_patch_time: 'Patch time',
    details_rpo_date: 'RPO date',
    details_rpo_time: 'RPO time',

    tooltip_columns_count_applied: 'Customers have applied this package',

    filter_module: 'Module',
    
    accumulated_texthelp:   `
                            <strong>Objective:</strong>
                            <p class="text-justify">Suggest the list of accumulated packages or continuous delivery that have sources to update according to the analysis of the environment indicated on the Home page.</p>
                            <br>
                            <strong>Fields:</strong>
                            <p class="text-justify">-Patch id: Internal package id;</p>
                            <p class="text-justify">-Ticket: Always '000000' because it is an internal process;</p>
                            <p class="text-justify">-Issue: Continuous delivery package name;</p>
                            <p class="text-justify">-Module: Module code that refers to the issue, which may be blank if not identified;</p>
                            <p class="text-justify">-Generation date: Package generation date;</p>
                            <p class="text-justify">-Issue type: On this page, always refers to Continuous Delivery;</p>
                            <p class="text-justify">-Resume: Brief summary of TDN documentation;</p>
                            <br>
                            <strong>Search/Filter fields:</strong>
                            <p class="text-justify">By module.</p>
                            <br>
                            <strong>More options:</strong>
                            <p class="text-justify">-Details: Displays the sources in the package.</p>
                            <p class="text-justify">-TDN documentation: Link to TDN documentation page.</p>
                            <p class="text-justify">-Download: Link to the download page.</p>
                        `,
}
