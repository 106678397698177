export const subscriptionPT = {
    title: 'Gerenciamento notificações',
    labelCodT: 'Codigo T.',
    labelTotvsId: 'TotvsId',
    labelVersion: 'Versão',
    labelEnvironment: 'Ambiente',
    labelServer: 'Servidor',
    labelPort: 'Porta',
    labelButtonClose: 'Fechar',
    labelButtonSave: 'Salvar',

    description_subscriber: 'Escolha as categorias para receber notificações:',
    description_all: 'Todos',

    post_success: 'Inscrição realizada com sucesso.' 
}
