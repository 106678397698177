export const homeES = {
    title: 'Clientes',
    appserver_group: 'APPSERVER',
    library_group: 'LIB',
    dbaccess_group: 'DBACCESS',
    version_label: 'Version',
    status_ok: 'Actualizado',
    customer_group: 'Datos del cliente',
    customer_id_label: 'ID',
    customer_name_label: 'Nombre',
    environment_group: 'Ambiente',
    environment_name_label: 'Nombre',
    environment_server_label: 'Servidor',
    environment_port_label: 'Puerta',
    environment_version_label: 'Version',
    environment_dbname_label: 'Nombre DB',
    environment_dbmstype_label: 'Tipo DB',
    environment_rposubmark_label:'RPO Sub-Marca',
    environment_dicsubmark_label:'Diccionario Sub-Marca',
    environment_dicindb_label:'Diccionario en DB',
    environment_dicindb_yes:'Si',
    environment_dicindb_no:'No',
    environment_apiversion_label: 'Versión Central',
    environment_installtype_label: 'Tipo de la Instalación',
    update_group: 'Envío de la última actualización',
    update_date: 'Fecha',
    update_hour: 'Hora',
};


