export const subscriptionEN = {
  title: 'Notifications Management',
  labelCodT: 'Code T.',
  labelTotvsId: 'TotvsId',
  labelVersion: 'Version',
  labelEnvironment: 'Environment',
  labelServer: 'Server',
  labelPort: 'Port',
  labelButtonClose: 'Close',
  labelButtonSave: 'Save',

  description_subscriber: 'Choose categories to receive notifications:',
  description_all: 'All',

  post_success: 'Subscription successfully made.'
}