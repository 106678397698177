export const bin_recommendedPT = {
    title: 'Lib e Binários',

    column_bin_type: 'Tipo de Binário',
    column_platform: 'Plataforma',
    column_bin_version: 'Versão do Ambiente',
    column_approved_version: 'Versão homologada',
    column_build_version:'Build do Ambiente',
    column_approved_build:'Build Homologada',
    column_published_date: 'Data de publicação',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    details_bin_type: 'Tipo de Binário',
    details_version: 'Versão',
    details_platform: 'Plataforma',
    details_resume: 'Resumo',

    filter_bin_type: 'Tipo de Binário',
    filter_platform: 'Plataforma',

    bin_recommended_texthelp:   `
                                    <strong>Objetivo:</strong>
                                    <p class="text-justify">Mostrar a lista libs e binários avaliados, comparando a versão do ambiente indicado na página Home com o último disponível.</p>
                                    <br>
                                    <strong>Campos:</strong>
                                    <p class="text-justify">-Status: Indica se atualizado ou não;</p>
                                    <p class="text-justify">-Tipo de Binário: Lib, Appserver e DbAccess;</p>
                                    <p class="text-justify">-Plataforma: Indica a plataforma de Binário, Windows ou Linux, 32 ou 64 Bits;</p>
                                    <p class="text-justify">-Versão: Versão no ambiente avaliado;</p>
                                    <p class="text-justify">-Build: Build no ambiente avaliado;</p>
                                    <p class="text-justify">-Versão Homologada: Última versão disponível para download;</p>
                                    <p class="text-justify">-Build Homologada: Última build disponível para download;</p>
                                    <br>
                                    <strong>Campos para Busca/Filtro:</strong>
                                    <p class="text-justify">Por Tipo de Binário e Por Plataforma.</p>
                                    <br>
                                    <strong>Mais opções:</strong>
                                    <p class="text-justify">-Detalhes: Exibi a lista de versão entre a versão do ambiente e a versão da totvs, bem como cada documentação específica.</p>
                                    <p class="text-justify">-Download: Redireciona ao link para página de download.</p>
                                `,

    title_artefacts_additional: 'Artefatos adicionais',
    obs_artects_additional: 'Não é possível realizar comparação de status de homologação para lista de artefatos acima.',

}
