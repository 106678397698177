export const contactPT = {
    title: "Sugestões",
    contact_btn_send: 'Enviar',
    contact_name: 'Nome',
    contact_phone: 'Telefone',
    contact_mail: 'E-mail',
    contact_comments: 'Comentário',
    contact_success_msg: 'Sugestão enviada com sucesso!',
    contact_btn_term_accept: 'Aceito',
    contact_btn_term_not_accept: 'Não aceito',
    contact_title_term: 'Termo de Aceite',
    contact_subtitle_term: 'Central de Atualizações - Protheus',
    contact_text_term: `
                        <p>
                            Armazenamos os dados nome, telefone e e-mail apenas para análise da sugestão e eventuais futuros atendimentos.
                            Ao clicar em <strong>"Aceito"</strong>, você consente com a utilização destes dados.
                        </p>
                        `,
}
