export const procempPT = {
    title: 'Processo x Empresa',

    //Coluna da tabela principal (tela inicial do component)
    column_spsprocdure: 'Código',
    column_spsroutine: "Rotina",
    column_spsdescription: 'Descrição do processo',
    column_spsdetailspack: 'Id. Details Pack',
    column_spsdate_date: 'Data do processo',
    column_spsdate_time: 'Hora do processo',
    column_total_outdated: 'Tot. Desatualizado',
    column_total_notinstalled: 'Tot. não instalado',
    column_total_updated: 'Tot. Atualizado',
    
    details_spstatus: 'Status do processo',
    details_company: 'Cod. Empresa',
    details_clientspsid: 'Id. Sps. Client',
    details_datahora: 'Data e Hora',
    details_hist_action: 'Histórico das ações',
    details_process: 'Cod. do Processo',
    details_clientdata: 'Dt. Client',
    details_clienthour: 'Hr. Client',
    details_totvsspsid: 'Id. Sps. Totvs',
    details_totvsdata: 'Dt. Totvs',
    details_totvshour: 'Hr. Totvs',
    details_hist_histdata: 'Dt. do processo',
    details_hist_histhour: 'Hr. do processo',

    details_label_updated: 'Atualizado',
    details_label_outdated: 'Desatualizado',
    details_label_not_installed: 'Não instalado',
    details_label_install: 'Instalado',
    details_label_remove: 'Removido',

    dsp_row_historical: 'Histórico',
    dsp_details: 'Detalhes',

    procemp_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Mostrar a lista de processos por empresa, detalhando cada processo e seu atual Status em seu ambiente.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Código: Identificador sequencial do processo.</p>
                                <p class="text-justify">-Rotina: Rotina da processo </p>
                                <p class="text-justify">-Descrição: Descrição da processo </p>
                                <p class="text-justify">-Data da processo: Data e hora do arquivo sps instalado no ambiente </p>
                                <strong>Totalizadores:</strong>
                                <p class="text-justify">-Não instalados: Somatório de cada procedure não instalada em seu grupo de empresas por processo.  </p>
                                <p class="text-justify">-Desatualizados: Somatório de cada procedure desatualizadas em seu grupo de empresas por processo.  </p>
                                <p class="text-justify">-Atualizados: Somatório de cada procedure atualizados em seu grupo de empresas por processo.  </p>
                                <br>
                                <strong>Campos para Busca/Filtro:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>Mais opções:</strong>
                                <p class="text-justify">-Detalhes: Listagem do processo por empresa e atual Status.</p>
                                <p class="text-justify">-Histórico: Listagem do processo por empresa e contendo do Histórico se esta instalado ou removido da empresa.</p>
                            `,
}
