export const sourcesEN = {
    title: 'Applied sources',

    column_source: 'Source',
    column_patch_date: 'Patch date',
    column_patch_time: 'Patch time',
    column_rpo_date: 'RPO date',
    column_rpo_time: 'RPO time',

    details_id: 'Id Patch',
    details_ticket: 'Ticket',
    details_issue: 'Issue',
    details_module: 'Module',
    details_generation_date: 'Generation date',
    details_issue_type: 'Issue type',
    details_resume: 'Resume',

    type_innovation: 'Innovation',
    type_maintenance: 'Maintenance',
    type_continuous_delivery: 'Continuous delivery',

    filter_source: 'Source',
    
    sources_texthelp:   `
                            <strong>Objective:</strong>
                            <p class="text-justify">Display the list of sources updated by packages applied in the environment indicated on the home page, as well as which packages each source has been updated.</p>
                            <br>
                            <strong>Fields:</strong>
                            <p class="text-justify">-Source: Source name;</p>
                            <p class="text-justify">-Patch date: Most up-to-date patch applied;</p>
                            <p class="text-justify">-Patch time: Most updated patch time applied;</p>
                            <p class="text-justify">-RPO date: Source date in the RPO. Usually the same as the patch unless you have accepted the application of older sources or applied an emergency patch ;</p>
                            <p class="text-justify">-RPO time: Source time in the RPO;</p>
                            <br>
                            <strong>Search/filter fields:</strong>
                            <p class="text-justify">By source.</p>
                            <br>
                            <strong>More options:</strong>
                            <p class="text-justify">-Details: Displays the list of packages containing the source.</p>
                        `,
}
