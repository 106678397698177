export const packagesPT = {
    title: 'Pacotes Aplicados',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_generation_date: 'Data geração',
    column_issue_type: 'Tipo de issue',
    column_resume: 'Resumo',

    type_innovation: 'Inovação',
    type_maintenance: 'Manutenção',
    type_continuous_delivery: 'Expedição contínua',

    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    details_source: 'Fonte',
    details_patch_date: 'Data Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Data RPO',
    details_rpo_time: 'Hora RPO',

    details_recommendation: 'Recomendação',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',
    filter_module: 'Módulo',
    filter_issue_type: 'Tipo de issue',
    
    packages_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Exibe a lista de pacotes aplicados no ambiente indicado na página home, bem como os detalhes de cada um desses pacotes.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id interno do pacote;</p>
                            <p class="text-justify">-Ticket: Número do primeiro ticket que encontrou o problema;</p>
                            <p class="text-justify">-Issue: Código da issue que corrigiu o problema;</p>
                            <p class="text-justify">-Modulo: Código do modulo referente a issue, podendo estar branco se não identificado;</p>
                            <p class="text-justify">-Data Geração: Data em que o pacote foi gerado;</p>
                            <p class="text-justify">-Tipo de Issue: Tipo da issue ou Pacote, podendo ser de Manutenção, Inovação ou Expedição Continua;</p>
                            <p class="text-justify">-Resumo: Breve resumo da documentação do TDN;</p>
                            <br>
                            <strong>Campos para Busca/Filtro:</strong>
                            <p class="text-justify">Por ticket, por Issue ou por Modulo.</p>
                            <br>
                            <strong>Mais opções:</strong>
                            <p class="text-justify">-Detalhes: Exibe os fontes contidos do pacote.</p>
                            <p class="text-justify">-Documentação TDN: Acessa a página do TDN com a documentação.</p>
                        `,

}
