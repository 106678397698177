export const procempES = {
    title: 'Proceso x compañia',

    //Coluna da tabela principal (tela inicial do component)
    column_spsprocdure: 'Id. Sps',
    column_spsroutine: 'Rutina',
    column_spsdescription: 'Descripción del proceso',
    column_spsdetailspack: 'Id. Details Pack',
    column_spsdate_date: 'Fecha de proceso',
    column_spsdate_time: 'Hora de proceso',
    column_total_outdated: 'Total desactualizado',
    column_total_notinstalled: 'Total no instalado',
    column_total_updated: 'Total actualizado',
    
    details_spstatus: 'Estado del procedimiento',
    details_company: 'Codigo de compañia',
    details_clientspsid: 'Id. Sps',
    details_datahora: 'Fecha y hora',
    details_hist_action: 'Historial de existencias',
    details_process: 'Código de proceso',
    details_clientdata: 'Fecha del cliente',
    details_clienthour: 'Tiempo del cliente',
    details_totvsspsid: 'Id. Sps. Servidor',
    details_totvsdata: 'Fecha del Servidor',
    details_totvshour: 'Tiempo de Servidor',
    details_hist_histdata: 'Fecha del proceso',
    details_hist_histhour: 'Tiempo del proceso',

    details_label_updated: 'Actualizado',
    details_label_outdated: 'Desactualizado',
    details_label_not_installed: 'No instalado',
    details_label_install: 'Instalado',
    details_label_remove: 'Removido',

    dsp_row_historical: 'Histórico',
    dsp_details: 'Detalles',

    procemp_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Muestre la lista de procedimientos almacenados por empresa, detallando cada proceso y su estado actual en su entorno.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Id. Sps: Identificador de procedimiento </p>
                                <p class="text-justify">-Rutina: Rutina de procedimiento </p>
                                <p class="text-justify">-Descripción del proceso: Descripción del procedimiento </p>
                                <p class="text-justify">-Fecha de proceso: Fecha y hora del archivo sps instalado en el entorno </p>
                                <strong>Totalizadores:</strong>
                                <p class="text-justify">-No instalado: Suma de cada procedimiento no instalado en su grupo de empresas por proceso.  </p>
                                <p class="text-justify">-Desactualizado: Suma de cada procedimiento desactualizado en su grupo de empresas por proceso.  </p>
                                <p class="text-justify">-Actualizado: Suma de cada trámite desactualizado en su grupo de empresas por proceso.  </p>
                                <br>
                                <strong>Campos de búsqueda / filtro:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>Mas opciones:</strong>
                                <p class="text-justify">-Detalles: Listado de procesos por empresa y estado actual.</p>
                                <p class="text-justify">-Historial: Listado del proceso por empresa y que contiene el Historial si está instalado o eliminado de la empresa.</p>
                            `,
}
