export const procempEN = {
    title: 'Process x Company',

    //Coluna da tabela principal (tela inicial do component)
    column_spsprocdure: 'Id. Sps',
    column_spsroutine: 'Routine',
    column_spsdescription: 'Description of procedure',
    column_spsdetailspack: 'Id. Details Pack',
    column_spsdate_date: 'Procedure date',
    column_spsdate_time: 'Time',
    column_total_outdated: 'Outdated sum',
    column_total_notinstalled: 'Not installed sum',
    column_total_updated: 'Updated sum',
    
    details_spstatus: 'Procedure Status',
    details_company: 'Company',
    details_clientspsid: 'Id. Sps',
    details_datahora: 'Date and time',
    details_hist_action: 'Stock history',
    details_process: 'Process Code',
    details_clientdata: 'Date Client',
    details_clienthour: 'Time Client',
    details_totvsspsid: 'Id. Sps. Totvs',
    details_totvsdata: 'Date Totvs',
    details_totvshour: 'Time Totvs',
    details_hist_histdata: 'Date do processo',
    details_hist_histhour: 'Time do processo',

    details_label_updated: 'Updated',
    details_label_outdated: 'Outdated',
    details_label_not_installed: 'Not installed',
    details_label_install: 'Installed',
    details_label_remove: 'Removed',

    dsp_row_historical: 'Historical',
    dsp_details: 'Details',

    procemp_texthelp:    `
                                <strong>Objective:</strong>
                                <p class="text-justify">Show the list of Stored Procedures by company, detailing each process and its current Status in your environment.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Id. Sps: Procedure identifier </p>
                                <p class="text-justify">-Routine: Procedure Routine </p>
                                <p class="text-justify">-Description: Description of procedure </p>
                                <p class="text-justify">-Procedure date: Date and time of the sps file installed in the environment </p>
                                <strong>Totalizers:</strong>
                                <p class="text-justify">-Not installed: Sum of each procedure not installed in your group of companies by process.  </p>
                                <p class="text-justify">-Outdated: Sum of each outdated procedure in your group of companies per process.  </p>
                                <p class="text-justify">-Updated: Sum of each outdated procedure in your group of companies per process.  </p>
                                <br>
                                <strong>Search/Filter Fields:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>More options:</strong>
                                <p class="text-justify">-Details: Process listing by company and current Status.</p>
                                <p class="text-justify">-Historical: Listing of the process by company and containing the History if it is installed or removed from the company.</p>
                            `,
}
