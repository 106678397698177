export const accumulatedPT = {
    title: 'Pacotes acumulados',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_generation_date: 'Data geração',
    column_public_date: 'Data de publicação',
    column_issue_type: 'Tipo de issue',
    column_resume: 'Resumo',
    column_count_applied: 'Utilizando',

    type_innovation: 'Inovação',
    type_maintenance: 'Manutenção',
    type_continuous_delivery: 'Expedição contínua',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    details_source: 'Fonte',
    details_patch_date: 'Data Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Data RPO',
    details_rpo_time: 'Hora RPO',

    tooltip_columns_count_applied: 'Clientes aplicaram esse pacote',

    filter_module: 'Módulo',

    accumulated_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Sugerir a lista dos pacotes acumulados/Expedição Continua que conforme a análise do ambiente indicado na página Home existem fontes com necessidade de atualização.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id interno do pacote;</p>
                            <p class="text-justify">-Ticket: Sempre '000000' se trata de processo interno;</p>
                            <p class="text-justify">-Issue: Nome do Pacote de Expedição Continua;</p>
                            <p class="text-justify">-Modulo: Código do modulo referente a issue, podendo estar branco se não identificado;</p>
                            <p class="text-justify">-Data Geração: Data em que o pacote foi gerado;</p>
                            <p class="text-justify">-Tipo de Issue: Nesta página sempre Expedição Continua;</p>
                            <p class="text-justify">-Resumo: Breve resumo da documentação do TDN;</p>
                            <br>
                            <strong>Campos para Busca/Filtro:</strong>
                            <p class="text-justify">Por Modulo.</p>
                            <br>
                            <strong>Mais opções:</strong>
                            <p class="text-justify">-Detalhes: Mostra os fontes contidos no pacote.</p>
                            <p class="text-justify">-Documentação TDN: Acessa a página do TDN com a documentação.</p>
                            <p class="text-justify">-Download: Redireciona ao link para página de download.</p>
                        `,

}
