export const dictionaryEN = {
    title: 'Dictionary',
    customer: 'Customer',
    column_id: 'Execution Id',
    column_group: "Group",
    column_exec_date: 'Execution date',
    column_exec_time: 'Execution time',
    column_process_id: 'Processing id',
    column_project_id: 'Project id',
    column_package_id: 'Package id',
    column_package_details: 'Package details',

    details_type: 'Type',
    details_description: 'Description',

    filter_project_id: 'Project id',
    filter_package_id: 'Package id',
    filter_exec_date: 'Exec. date from ',

    dictionary_texthelp:    `
                                <strong>Objective:</strong>
                                <p class="text-justify">Show the list of upddistr executions, detailing the package that was applied and not the changes made to the database.</p>
                                <br>
                                <strong>Fields:</strong>
                                <p class="text-justify">-Execution Id: Sequential execution ID;</p>
                                <p class="text-justify">-Group: Company group that ran the upddistr;</p>
                                <p class="text-justify">-Execution date: Date the upddistr was run;</p>
                                <p class="text-justify">-Execution time: Time the upddistr was run;</p>
                                <p class="text-justify">-Processing Id: Processing id that links database change logs;</p>
                                <p class="text-justify">-Project Id: Internal id of the project id that the package refers to;</p>
                                <p class="text-justify">-Package Id: Internal package id;</p>
                                <p class="text-justify">-Package detail: Description detailing the package;</p>
                                <br>
                                <strong>Search/filter fields:</strong>
                                <p class="text-justify">By package id, by project id and execution date.</p>
                                <br>
                                <strong>More options:</strong>
                                <p class="text-justify">-Details: Additional package details.</p>
                            `,
}