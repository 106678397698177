export const contactES = {
    title: "Sugerencia",
    contact_btn_send: 'Enviar',
    contact_name: 'Nombre',
    contact_phone: 'Teléfono',
    contact_mail: 'Correo Electrónico',
    contact_comments: 'Comentario',
    contact_success_msg: 'Sugerencia enviada con éxito!',
    contact_btn_term_accept: 'Acepto',
    contact_btn_term_not_accept: 'No acepto',
    contact_title_term: 'Plazo de aceptación',
    contact_subtitle_term: 'Centro de actualización - Protheus',
    contact_text_term: `
                        <p>
                            Almacenamos los datos de nombre, teléfono y correo electrónico para el análisis de la sugerencia y eventual asistencia futura.
                            Al hacer clic en <strong> "Acepto" </strong>, acepta el uso de estos datos.
                        </p>
                        `,
}
