export const modulePT = {
    title: 'Pacotes do módulo',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_gen_date: 'Data geração',
    column_p_date: 'Data de publicação',
    column_issue_type: 'Tipo de issue',
    column_resume: 'Resumo',
    column_count_applied: 'Usando',

    type_inovation: 'Inovação',
    type_maintenance: 'Manutenção',
    type_continuous_delivery: 'Expedição contínua',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    details_source: 'Fonte',
    details_patch_date: 'Data Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Data RPO',
    details_rpo_time: 'Hora RPO',

    tooltip_columns_count_applied: 'Clientes aplicaram esse pacote',

    combo_name_filter: 'Trocar de módulo',
    containter_text_model01: 'Atenção',
    containter_text_model02: 'Nesta visualização está com filtro somente para os pacotes do módulo: ',
    containter_text_model03: "Caso houver a necessidade de visualizar outros módulos, altere em 'Trocar de módulo' ",

    details_recommendation: 'Recomendação',

    filter_ticket: 'Ticket',
    filter_issue: 'Issue',

    module_texthelp:  `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Sugerir a lista dos pacotes do módulo logado conforme a análise do ambiente indicado na página Home e os fontes que necessitam de atualização.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id interno do pacote;</p>
                            <p class="text-justify">-Ticket: Número do primeiro ticket que encontrou o problema;</p>
                            <p class="text-justify">-Issue: Código da issue que corrigiu o problema;</p>
                            <p class="text-justify">-Modulo: Código do modulo referente a issue;</p>
                            <p class="text-justify">-Data Geração: Data em que o pacote foi gerado;</p>
                            <p class="text-justify">-Tipo de Issue: Nesta página sempre Manutenção;</p>
                            <p class="text-justify">-Resumo: Breve resumo da documentação do TDN;</p>
                            <br>
                            <strong>Campos para Busca/Filtro:</strong>
                            <p class="text-justify">Por Ticket e Por Issue.</p>
                            <br>
                            <strong>Mais opções:</strong>
                            <p class="text-justify">-Detalhes: Mostra os fontes contidos do pacote.</p>
                            <p class="text-justify">-Documentação TDN: Acessa a página do TDN com a documentação.</p>
                            <p class="text-justify">-Download: Redireciona ao link para página de download.</p>
                        `,

}
