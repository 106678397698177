export const dictionaryES = {
    title: 'Dicionario',
    customer: 'Cliente',
    column_id: 'Id Ejecución',
    column_group: "Grupo",
    column_exec_date: 'Fecha ejecución',
    column_exec_time: 'Hora ejecución',
    column_process_id: 'Id procesando',
    column_project_id: 'Id del proyecto',
    column_package_id: 'Id del paquete',
    column_package_details: 'Detallando paquete',

    details_type: 'Tipo',
    details_description: 'Descripción',

    filter_project_id: 'Id del proyecto',
    filter_package_id: 'Id del paquete',
    filter_exec_date: 'Fecha Ejec. Desde',

    dictionary_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Muestra la lista de ejecuciones de upddistr, detallando el paquete que se aplicó y no los cambios realizados en la base de datos.</p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Id Ejecución: ID de ejecución secuencial;</p>
                                <p class="text-justify">-Grupo: Grupo de empresa que ha realizado el upddistr;</p>
                                <p class="text-justify">-Fecha Ejecución: Fecha en que se ejecutó upddistr;</p>
                                <p class="text-justify">-Hora Ejecución: Hora en que se ejecutó upddistr;</p>
                                <p class="text-justify">-Id Procesando: Id de procesamiento que vincula los registros de cambios de la base de datos;</p>
                                <p class="text-justify">-Id del Proyecto: Id interno del proyecto al que se refiere el paquete;</p>
                                <p class="text-justify">-Id del Paquete: Id interno del paquete;</p>
                                <p class="text-justify">-Detalle Paquete: Descripctivo detallando el paquete;</p>
                                <br>
                                <strong>Campos de Búsqueda/Filtro:</strong>
                                <p class="text-justify">Por Id del Paquete, Por Id de Proyecto y Fecha Ejecución.</p>
                                <br>
                                <strong>Mas opciones:</strong>
                                <p class="text-justify">-Detalles: Detalles adicionales del paquete.</p>
                            `,

}
