export const applicationPT = {
    title: 'Central de Atualizações - Protheus',
    title_slide: 'Notificações',
    title_slide_config: 'Gerenciamento de notificações',
    my_environment: 'Meu ambiente',
    environment_label: 'Ambiente',
    sub_item_packages: 'Pacotes aplicados',
    sub_item_packages_1: 'Pacote x Fonte',
    sub_item_packages_2: 'Fonte x Pacote',
    sub_item_dictionary: 'Dicionário',
    sub_item_procedures: 'Procedures',
    sub_item_procedures_1: 'Empresa x Processo',
    sub_item_procedures_2: 'Processo x Empresa',
    available_updates: 'Atualizações disponíveis',
    available_updates_label: 'Atualizações',
    sub_item_accumulated_packages: 'Pacotes acumulados',
    sub_item_module_packages: 'Pacotes do módulo',
    sub_item_source_list: 'Lista de fontes',
    sub_item_lib_and_bin: 'Lib e Binário',
    suggestions: 'Sugestões',
    suggestions_label: 'Sugestões',
    notification_config_title: 'Configurações',
    notifications_label: 'Notificações',
    notifications_settings: 'Gerenciar inscrições',
    notifications_columns_title: ['Data', 'Título', 'Resumo'],
    msg_session_expired: 'Sessão expirada! Por favor, abra a página novamente.',
    msg_session_unauthorized: 'Sessão não autorizada! Por favor realize o acesso novamente.',
    msg_request_incomplete: 'Não foi possível concluir a solicitação',
    subscriptions: 'Inscrições',
    subscriptions_label: 'Inscrições de notificações',

    //-- literais usadas no componente "dynamic-search-page"
    dsp_filter_title: 'Busca avançada',
    dsp_group_title: 'Filtros aplicados',
    dsp_cancel_btn: 'Cancelar',
    dsp_confirm_btn: 'Aplicar',
    dsp_search_placeholder: 'pesquise aqui',
    dsp_details: 'Detalhes',
    dsp_tdn_documentation: 'Documentação TDN',
    dsp_tdn_documentation_details: 'Doc. TDN',
    dsp_no_package_documentation: 'O pacote selecionado não possui documentação disponível',
    dsp_hlp_how_to_use: 'Como usar?',
    dsp_hlp_resume: 'Resumo',
    dsp_hlp_search: 'Pesquisar',

}
