export const empprocPT = {
    title: 'Empresa x Processo',

    //Coluna da tabela principal (tela inicial do component)
    column_company: 'Cod. Empresa',
    column_total_outdated: 'Tot. Desatualizado',
    column_total_notinstalled: 'Tot. não instalado',
    column_total_updated: 'Tot. Atualizado',

    detail_spsprocdure: 'Cód.',
    details_spstatus: 'Status da processo',
    details_sps_clientspsid: 'Id. Client',
    details_sps_clientdata: 'Data Client',
    details_sps_totvsspsid: 'Id. Totvs',
    details_sps_totvsdata: 'Data Totvs',
    details_company: 'Empresa',
    details_process: 'Cod. do Processo',
    details_idsps: 'Id. Sps',
    details_datahora: 'Data e Hora',
    details_sps_clienthour: 'Hora Client',
    details_sps_totvshour: 'Hora Totvs',
    details_histdata: 'Data do histórico',
    details_histhour: 'Hora do histórico',

    details_label_updated: 'Atualizado',
    details_label_outdated: 'Desatualizado',
    details_label_not_installed: 'Não instalado',

    details_hist_action: 'Histórico das ações',
    details_label_install: 'Instalado',
    details_label_remove: 'Removido',

    dsp_row_historical: 'Histórico',
    dsp_details: 'Detalhes',

    empproc_texthelp:    `
                                <strong>Objetivo:</strong>
                                <p class="text-justify">Mostrar a lista de empresas por stored procedures, detalhando cada processo e seu atual Status em seu ambiente.</p>
                                <br>
                                <strong>Icone de download:</strong>
                                <p class="text-justify">-Download: Caso o link do stored procedure esteja habilitado no portal, ao clicar no botão será direcionado para o portal de download's da Totvs.</p>
                                <br>
                                <strong>Modo de visualização:</strong>
                                <p class="text-justify">-Card's: Lista as empresas por card com os devidos totalizados de sua respectiva empresa </p>
                                <p class="text-justify">-Lista: Demonstra a lista de empresa como tabela </p>
                                <br>
                                <strong>Campos:</strong>
                                <p class="text-justify">-Empresa: Código de empresa do Protheus </p>
                                <strong>Totalizadores:</strong>
                                <p class="text-justify">-Não instalados: Somatório de cada procedure não instalada em seu grupo de empresas por processo. </p>
                                <p class="text-justify">-Desatualizados: Somatório de cada procedure desatualizadas em seu grupo de empresas por processo. </p>
                                <p class="text-justify">-Atualizados: Somatório de cada procedure atualizados em seu grupo de empresas por processo. </p>
                                <br>
                                <strong>Campos para Busca/Filtro:</strong>
                                <p class="text-justify">-</p>
                                <br>
                                <strong>Mais opções:</strong>
                                <p class="text-justify">-Id. Sps: Código do Stored Procedure. </p>
                                <p class="text-justify">-Id. Client: Identificador do versionamento do Stored Procedure. </p>
                                <p class="text-justify">-Dt. Client: Data da ultima atualização Stored Procedure no Client. </p>
                                <p class="text-justify">-Id. Totvs: Identificador do versionamento do Stored Procedure na central. </p>
                                <p class="text-justify">-Dt. Totvs: Data da ultima atualização Stored Procedure na central. </p>
                                <p class="text-justify">-Status da Procedure: </p>
                                <p class="text-justify">-Atualizado: Identificador da Stored Procedure está idêntico ao disponibilizado pela central.  </p>
                                <p class="text-justify">-Desatualizado: Identificador da Stored Procedure está inferior ao identificador disponibilizado pela central.  </p>
                                <p class="text-justify">-Não Instalado: Não identificado o Id instalado no ambiente do cliente.  </p>
                                <br>
                                <strong>Histórico:</strong>
                                <p class="text-justify">-Empresa: Código da empresa. </p>
                                <p class="text-justify">-Id. Sps: Identificador do versionamento da Stored Procedure. </p>
                                <p class="text-justify">-Código do Processo: Código sequencial da Stored Procedure. </p>
                                <p class="text-justify">-Data e Hora: Data e hora da ação.  </p>
                                <p class="text-justify">-Status da Procedure: </p>
                                <p class="text-justify">-Removido: Removido a Stored Procedure da empresa.  </p>
                                <p class="text-justify">-Instalado: Instaldo o Stored Procedure.  </p>
                            `,
}
