export const accumulatedES = {
    title: 'Paquetes acumulados',

    column_id: 'Id Patch',
    column_ticket: 'Ticket',
    column_issue: 'Issue',
    column_module: 'Módulo',
    column_generation_date: 'Fecha generación',
    column_public_date: 'Fecha de publicación',
    column_issue_type: 'Tipo de issue',
    column_resume: 'Resumen',
    column_count_applied: 'Aplicado',

    type_innovation: 'Innovación',
    type_maintenance: 'Mantenimiento',
    type_continuous_delivery: 'Expedición continúa',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query 
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query 
    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    details_source: 'Fuente',
    details_patch_date: 'Fecha Patch',
    details_patch_time: 'Hora Patch',
    details_rpo_date: 'Fecha RPO',
    details_rpo_time: 'Hora RPO',

    tooltip_columns_count_applied: 'Los clientes han aplicado este paquete',

    filter_module: 'Módulo',
    
    accumulated_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Sugerir el listado de paquetes acumulados / Expedición Continúa que según el análisis del ambiente indicado en la página del Inicio hay fuentes que necesitan actualización.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Id Patch:Id del paquete interno;</p>
                            <p class="text-justify">-Ticket: Siempre '000000' es un proceso interno;</p>
                            <p class="text-justify">-Issue: Nombre del paquete de la Expedición Continúa;</p>
                            <p class="text-justify">-Modulo: Código del módulo referente al issue, que puede ser blanco si no hay identificación;</p>
                            <p class="text-justify">-Fecha generación: Fecha en que se generó el paquete;</p>
                            <p class="text-justify">-Tipo de Issue: En esta página siempre la expedición continúa;</p>
                            <p class="text-justify">-Resumen: Breve resumen de la documentación TDN;</p>
                            <br>
                            <strong>Campos de Búsqueda/Filtro:</strong>
                            <p class="text-justify">Por Modulo.</p>
                            <br>
                            <strong>Mas opciones:</strong>
                            <p class="text-justify">-Detalles: Muestra las fuentes contenidas en el paquete.</p>
                            <p class="text-justify">-Documentación TDN: Acceso a la página TDN con la documentación.</p>
                            <p class="text-justify">-Descargar: Redirige al enlace a la página de descarga.</p>
                        `,
}
