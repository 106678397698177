export const bin_recommendedEN = {
    title: 'Library & Binaries',

    column_bin_type: 'Binary type',
    column_platform: 'Platform',
    column_bin_version: 'Environment Version',
    column_approved_version: 'Approved version',
    column_build_version:'Environment Build',
    column_approved_build:'Approved Build',
    column_published_date: 'Publication date',

    status_outdated_value: 'Desatualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_uptodate_value: 'Atualizado', // não podem ser traduzidos pois são os valores da query da API bin_recommended.php
    status_outdated_label: 'Outdated',
    status_uptodate_label: 'Up-to-date',

    details_bin_type: 'Binary type',
    details_version: 'Version',
    details_platform: 'Platform',
    details_resume: 'Resume',

    filter_bin_type: 'Binary type',
    filter_platform: 'Platform',

    bin_recommended_texthelp:   `
                                    <strong>Objective:</strong>
                                    <p class="text-justify">Display the list of libs and binaries, comparing the version of the environment indicated on the Home page with the latest available.</p>
                                    <br>
                                    <strong>Fields:</strong>
                                    <p class="text-justify">-Status: Indicates whether is updated or not;</p>
                                    <p class="text-justify">-Binary type: Lib, Appserver and DbAccess;</p>
                                    <p class="text-justify">-Platform: Indicates the binary platform, Windows or Linux, 32 or 64 Bits;</p>
                                    <p class="text-justify">-Version: Evaluated environment version;</p>
                                    <p class="text-justify">-Build: Evaluated environment build;</p>
                                    <p class="text-justify">-Approved version: Latest version available to download;</p>
                                    <p class="text-justify">-Approved Build: Latest build available to download;</p>
                                    <br>
                                    <strong>Search/Filter fields:</strong>
                                    <p class="text-justify">By binary type or by platform.</p>
                                    <br>
                                    <strong>More options:</strong>
                                    <p class="text-justify">-Details: Displays the list of versions between the environment version and the totvs version, as well as each specific documentation.</p>
                                    <p class="text-justify">-Download: Link to the download page.</p>
                                `,

    title_artefacts_additional: 'Additional artifacts',
    obs_artects_additional: 'It is not possible to compare the approval status for the list of artifacts above.',

}
