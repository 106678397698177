export const rpo_listEN = {
    title: 'Source list',

    status_outdated_value: 'RPO Desatualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_uptodate_value: 'RPO Atualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_outdated_label: 'Outdated',
    status_uptodate_label: 'Up-to-date',

    column_source: 'Source',
    column_patch_date: 'Patch date',
    column_patch_time: 'Patch time',
    column_rpo_date: 'RPO date',
    column_rpo_time: 'RPO time',

    details_issue: 'Issue',
    details_recommendation: 'Recommendation',

    filter_source: 'Source',

    rpo_list_texthelp:  `
                            <strong>Objective:</strong>
                            <p class="text-justify">Display the list of all RPO sources except lib and compare the source date in the RPO with the latest version available on Totvs.</p>
                            <br>
                            <strong>Fields:</strong>
                            <p class="text-justify">-Source: Source name;</p>
                            <p class="text-justify">-Patch date: Last available version date;</p>
                            <p class="text-justify">-Patch time: Last available version time;</p>
                            <p class="text-justify">-RPO date: Source date in RPO;</p>
                            <p class="text-justify">-RPO time: Source time in RPO;</p>
                            <p class="text-justify">-Status: Evaluates whether it is updated or not, respecting possible differences in compilation. Note: Sources with an exact 1 hour difference will be considered equal.;</p>
                            <br>
                            <strong>Search/Filter fields:</strong>
                            <p class="text-justify">By source.</p>
                            <br>
                            <strong>More options:</strong>
                            <p class="text-justify">-Details: Displays the packages containing the source and makes it possible to download them. The issue package with the most current source and continuous shipments that have the most updated source that in the RPO will be listed.</p>
                        `,

}
